import React from 'react';

import "../../../styles/ServicesHoverCards.scss"

function AggregatesHoverCards() {
    const hoverDefault = " "
    let [hover, setHover] = React.useState(hoverDefault);
    let t : any;
    return (
        <div className={"soilContainer"}>
            <div onMouseEnter={() => {t = setTimeout(()=>{ setHover("1")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175);}}
                 className={"hoverBox"}> {hover === "1" ? <div className={"blur"}>

                <p>
                    By Following AS 1289 1.4.2 and AS 1289 1.2.1 we ensure our Gathered samples are Correct and accurate.
                </p>
                <p>
                    These methods outline the ways to sample.
                </p>
            </div> : <div>  <h1>Sampling Soils</h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("2")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175);}}
                 className={"hoverBox"}> {hover === "2" ? <div className={"blur"}>
                <p>
                    This test is an accurate way for working out quantities of civil materials required for earthworks, fill, trenches etc.
                    the test can be performed at various moisture contents and also giving you results in compacted or uncompacted state as well as.
                </p>
            </div> : <div> <h1> Bearing Ratio </h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("3")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "3" ? <div className={"blur"}>
                <p>
                    The test is carried out mainly on road base blends on both the fine and coarse fractions of the material.
                    the higher the particle density the lower the water absorption the better the quality of the material.
                </p>
            </div> : <div> <h1> Dry density Ratio </h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("4")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "4" ? <div className={"blur"}>
                <p>
                    The aggregate is put through a nest of various sieve sizes relating to its required specification to
                    insure the correct mechanical interlocking is achieved.
                </p>
            </div> : <div> <h1> Density index </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("5")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "5" ? <div className={"blur"}>
                <p> These tests </p>
                <p>
                    All insure the material tested is not to flaky and there is enough angularity in the aggregate to play its important role in
                    maximum mechanical interlocking.
                </p>
            </div> : <div> <h1> Minimum and maximum dry density  </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("6")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "6" ? <div className={"blur"}>
                <p>
                    These tests are performed to determine the strength of the most predominant aggregate size that is being used in the material
                    by the use of compression machine or the pulverising of the material.
                </p>
            </div> : <div> <h1> Particle density, sieve analysis </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("7")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "7" ? <div className={"blur"}>
                <p>
                    This involves the testing of the intermediate sizes of the material by finding out what fines will be
                    generated by putting the raw material through a self abrasion process.
                </p>
            </div> : <div> <h1> plasticity index, linear shrinkage, moisture content, plastic limit, liquid limit </h1>

            </div>} </div> { /*

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("8")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "8" ? <div className={"blur"}>
                <p>
                    bla bla bla ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla
                    ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla
                </p>
            </div> : <div> <h1> Filler Title </h1>

            </div>} </div> */}
        </div>
    );
}

export default AggregatesHoverCards;